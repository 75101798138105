
import BaseService from 'services/BaseService'
import JSZip from 'jszip'
class XMLClass{

 formatDateXml(date) {
    date = new Date(date)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

    async getReglementFact(idfact){
      
      const reglement = (await BaseService.post('/XML/compta.factc.load/get_reg_fact_c',{'lbl_int':'REG_FAC_D_ACH_'+idfact})).data
    
  
    if (typeof reglement[0] === 'undefined') {
      return {'pai':7,'date':null}
    }
    const element = reglement[0]['lbl_piece'].split('__')
    const piece = element[0]
    const id = element[1]
    let paiement
    if (piece.startsWith('banque') || piece.startsWith('caisse') ) {
    const tr = (await BaseService.post('/XML/tr.ba.load/get_transaction_by_id',{'compte':piece,'id':id})).data

  
    if (tr[0]['nature'].toLowerCase() == 'esp') {
      paiement = 1
    }else if(tr[0]['nature'].toLowerCase() == 'c'){
      paiement = 2
    }else if(tr[0]['nature'].toLowerCase() == 'v'){
      paiement = 4
      }
    
    
    
    }else if (piece.startsWith('ec')) {
      paiement = 5
    
    
    }else if(piece.startsWith('Avoir')){
      paiement = 6
    
    
    }
  
    
    return {'pai':paiement,'date':reglement[0]['date']}
    
    }
  
  
  
  
  
  
    async getReglementAvoir(idfact){
      
      const reglement = (await BaseService.post('/XML/compta.factc.load/get_reg_avoir_c',{'lbl_av':'REG_FAC_A_ACH_'+idfact})).data
    
    if (typeof reglement[0] === 'undefined') {
      return {'pai':7,'date':null}
    }
    const element = reglement[0]['lbl_piece'].split('__')
    const piece = element[0]
    const id = element[1]
    let paiement
    if (piece.startsWith('banque') || piece.startsWith('caisse') ) {
    const tr = (await BaseService.post('/XML/tr.ba.load/get_transaction_by_id',{'compte':piece,'id':id})).data

  
    if (tr[0]['nature'].toLowerCase() == 'esp') {
      paiement = 1
    }else if(tr[0]['nature'].toLowerCase() == 'c'){
      paiement = 2
    }else if(tr[0]['nature'].toLowerCase() == 'v'){
      paiement = 4
      }
    
    
    
    }else if (piece.startsWith('ec')) {
      paiement = 5
    
    
    }else if(piece.startsWith('Avoir')){
      paiement = 6
    
    
    }
  
    
    return {'pai':paiement,'date':reglement[0]['date']}
    
    }
  
      
  async ReleveDeDuctionTva(declaration,factures,info){
     let regime = 1
     if (declaration[0]['regime'] == 'T') {
      regime = 2
     }
          let xmlData = `
        <DeclarationReleveDeduction>
          <identifiantFiscal>`+info['IF']+`</identifiantFiscal>
          <annee>`+declaration[0]['annee']+`</annee>
          <periode>`+declaration[0]['periode']+`</periode>
          <regime>`+regime+`</regime>
          <releveDeductions>
            
         
          `;
  
  
  let ordre = 1
   factures = factures.factures
  //////////factures doit
  const keysfactures = Object.keys(factures)
  for (let i = 0; i < keysfactures.length; i++) {
    const element = keysfactures[i];
    
  
    const facture = factures[element].facture
    const fact = factures[element].ttc.fact
    const ttc = factures[element].ttc
    const fournisseur = factures[element].fournisseur
    let paiement
    let result
    let prefix = ''
    if (factures[element].t == 'doit') {
       result = await this.getReglementFact(fact['id']);
      paiement = (typeof result.pai === 'number') ? result.pai : 7;
    }else{
     
      prefix = '-'
      result = await this.getReglementAvoir(fact['id']);
      paiement = (typeof result.pai === 'number') ? result.pai : 7;
    }
  
    
    if (result.date == null) {
      result.date = fact['date']
    }
  ////////////////////les factures avoir
      const ht_tva = ttc.objttc.ht_tva
      const tva = ttc.objttc.tva
      const keys = Object.keys(ht_tva)
      for (let index = 0; index < keys.length; index++) {
        const ket = keys[index];
        const total_ttc = Math.round((Number(ht_tva[ket]) + Number(tva[ket]))*100)/100
        const taux = Number(ket.substring(3, ket.length - 1))
        xmlData = xmlData + `<rd>
        <ord>`+ordre+`</ord>
        <num>`+facture.num_format+`</num>
        <des>`+fact.description+` </des>
        <mht>`+prefix+ht_tva[ket]+`</mht>
        <tva>`+prefix+tva[ket]+`</tva>
        <ttc>`+prefix+total_ttc+`</ttc>
        <refF>
          <if>`+fournisseur['identifiant_fiscale']+`</if>
          <nom>`+fournisseur['RS']+`</nom>
          <ice>`+fournisseur['ICE']+`</ice>
        </refF>
        <tx>`+taux+`</tx>
        <prorata>`+facture.pr+`</prorata>
        <mp>
          <id>`+paiement+`</id>
        </mp>
        <dpai>`+this.formatDateXml(result.date)+`</dpai>
        <dfac>`+this.formatDateXml(fact['date'])+`</dfac>
      </rd>`
      ordre++
  
  
        
      }
    
  }
  
  
  
  
  
  
  
  
  
  
  xmlData = xmlData + ` </releveDeductions>
  </DeclarationReleveDeduction>`
  
  
  
  
  // Create a new JSZip instance.
  const zip = new JSZip();
  
  // Add the XML data to the zip file.
  zip.file('ReleveDeduction.xml', xmlData);
  
  // Generate the zip file.
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
    // Create a URL for the zip file.
    const url = URL.createObjectURL(zipFile);
  
    // Create an anchor element to trigger the download.
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ReleveDeduction.zip';
    a.click();
  
    // Release the URL object.
    URL.revokeObjectURL(url);
  });
  
      
        
  }
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async contribuableNResident(declaration,factures,info){
    factures = factures.nresident
  
    if (Object.keys(factures).length == 0) {
      return
    }
    let regime = 1
    if (declaration[0]['regime'] == 'T') {
     regime = 2
    }
         let xmlData = `
         <declarationNonResidents>
         <identifiantFiscal>`+info['IF']+`</identifiantFiscal>
         <annee>`+declaration[0]['annee']+`</annee>
         <periode>`+declaration[0]['periode']+`</periode>
         <regime>`+regime+`</regime>
         <nonResidents>
  
        
         `;
  
  
  let ordre = 1
  
  //////////factures doit
  const keysfactures = Object.keys(factures)
  for (let i = 0; i < keysfactures.length; i++) {
   const element = keysfactures[i];
   
  
   const facture = factures[element].facture
   const fact = factures[element].ttc.fact
   const ttc = factures[element].ttc
   const fournisseur = factures[element].fournisseur
   let paiement
   let result
   let prefix = ''
   if (factures[element].t == 'doit') {
      result = await this.getReglementFact(fact['id']);
     paiement = (typeof result.pai === 'number') ? result.pai : 7;
   }else{
    
     prefix = '-'
     result = await this.getReglementAvoir(fact['id']);
     paiement = (typeof result.pai === 'number') ? result.pai : 7;
   }
  
   
   if (result.date == null) {
     result.date = fact['date']
   }
  ////////////////////les factures avoir
     const ht_tva = ttc.objttc.ht_tva
     const tva = ttc.objttc.tva
     const keys = Object.keys(ht_tva)
     for (let index = 0; index < keys.length; index++) {
       const ket = keys[index];
       const total_ttc = Math.round((Number(ht_tva[ket]) + Number(tva[ket]))*100)/100
       const taux = Number(ket.substring(3, ket.length - 1))
       xmlData = xmlData + `
       <nonResident>
  <nomPrenomOuRaisonSociale>`+fournisseur['RS']+`</nomPrenomOuRaisonSociale>
  <adresseEtranger>`+fournisseur['adresse']+`</adresseEtranger>
  <nIdentificationFiscale>`+fournisseur['identifiant_fiscale']+`</nIdentificationFiscale>
  <natureOperation>`+fact.description+`</natureOperation>
  <datePaiement>`+this.formatDateXml(result.date)+`</datePaiement>
  <baseImposableHT>`+prefix+ht_tva[ket]+`</baseImposableHT>
  <taux>`+taux+`</taux>
  <tvaExigible>`+prefix+tva[ket]+`</tvaExigible>
  </nonResident>`
     ordre++
  
  
       
     }
   
  }
  
  
  
  
  
  
  
  
  
  
  xmlData = xmlData + ` </nonResidents>
  </declarationNonResidents>`
  
  
  
  
  // Create a new JSZip instance.
  const zip = new JSZip();
  
  // Add the XML data to the zip file.
  zip.file('ContribuablesNonResidents.xml', xmlData);
  
  // Generate the zip file.
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
   // Create a URL for the zip file.
   const url = URL.createObjectURL(zipFile);
  
   // Create an anchor element to trigger the download.
   const a = document.createElement('a');
   a.href = url;
   a.download = 'ContribuablesNonResidents.zip';
   a.click();
  
   // Release the URL object.
   URL.revokeObjectURL(url);
  });
  
     
       
  }
  
  
  
  
  
  
  
  
  
  
  
  
  damancom(name,txt) {
    
  
    // Create a Blob object with the file content and set the MIME type as text/plain
    const blob = new Blob([txt], {type: "text/plain"});
  
    // Create an anchor element (<a>)
    const downloadLink = document.createElement("a");
  
    // Set the download attribute of the anchor element with the desired file name
    downloadLink.download = name;
  
    // Set the href attribute of the anchor element to the URL of the Blob object
    downloadLink.href = URL.createObjectURL(blob);
  
    // Append the anchor element to the document, click it to start the download, and then remove it
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  
  
  
  }
  
  
  
 export default XMLClass