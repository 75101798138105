import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { notificationConfig } from './constants'
import { formatDateTime }  from '../../functions/formatDate'

// Redux Toolkit slice
const statistiquesVentesSlice = createSlice({
    name: 'statistiquesVentes',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatistiquesVentes.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchStatistiquesVentes.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchStatistiquesVentes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            
    },
})

// Async thunk action to fetch data from the API
export const fetchStatistiquesVentes = createAsyncThunk(
    'statistiquesVentes/fetch',
    async ({ startDate, endDate }) => {
        
        // Check for existence of startDate and endDate
        if (!startDate || !endDate) {
            throw new Error('Missing startDate or endDate');
        }
        
        try {
            const response = await BaseService.get(
                `/XML/ve.stat.load?startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`
            )
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch statistiquesVentes')
        }
    }
)




export const selectData = (state) => state.statistiquesVentes.data
export const selectIsLoading = (state) => state.statistiquesVentes.isLoading
export const selectError = (state) => state.statistiquesVentes.error

export default statistiquesVentesSlice.reducer
