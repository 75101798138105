import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const autresPpsSlice = createSlice({
    name: 'autresPps',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsAutresPps: (state) => {
            if (state.detailsAutresPps == null) {
                state.detailsAutresPps = ''
            }else{
                state.detailsAutresPps = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAutresPps.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchAutresPps.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchAutresPps.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchDetailsAutresPps.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsAutresPps.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsAutresPps = action.payload
            })
            .addCase(fetchDetailsAutresPps.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createAutresPp.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createAutresPp.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createAutresPp.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteAutresPp.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteAutresPp.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteAutresPp.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchAutresPps = createAsyncThunk(
    'autresPps/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/tr.autrepp.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch autresPps')
        }
    }
)



export const fetchDetailsAutresPps = createAsyncThunk(
    'autresPps/fetchDetails',
    async (id) => {
        try {
            const response = await BaseService.post('/XML/tr.autrepp.load/get_one', {'idau':id.id})
           return response.data
        } catch (err) {
          
            throw new Error('Failed to fetch autresPps')
        }
    }
)



export const createAutresPp = createAsyncThunk(
    'autresPps/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.autrepp.send/add_autrepp', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)


export const editAutresPp = createAsyncThunk(
    'autresPps/edit',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.autrepp.send/m_autrepp', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)



export const deleteAutresPp = createAsyncThunk(
    'autresPp/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/autresPps/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.autresPps.data
export const selectIsLoading = (state) => state.autresPps.isLoading
export const selectError = (state) => state.autresPps.error
export const selectIsDeleted = (state) => state.autresPps.isDeleted
export const selectCurrentPage = (state) => state.autresPps.currentPage
export const resetDetailsAutresPps = autresPpsSlice.actions.resetDetailsAutresPps
export default autresPpsSlice.reducer
