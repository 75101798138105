import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const receptionsSlice = createSlice({
    name: 'receptions',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsReceptions.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsReceptions.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsReceptions = action.payload
            })
            .addCase(fetchDetailsReceptions.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })



            .addCase(fetcAutresCharges.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetcAutresCharges.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.autresCharges = action.payload
            })
            .addCase(fetcAutresCharges.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })


            .addCase(fetchReceptions.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchReceptions.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchReceptions.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createReception.pending, (state) => {
                // state.isLoading = true
                state.error = null
            })
            .addCase(createReception.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createReception.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteReception.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteReception.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteReception.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchReceptions = createAsyncThunk(
    'receptions/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/st.reception.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch receptions')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsReceptions = createAsyncThunk(
    'Receptions/fetchDetails',
    async (id) => {
        const data = { 'idr': id.id }
        try {
            const Reception = await BaseService.post(
                `/XML/st.reception.load/get_one_reception`, data
            )
            const content = await BaseService.post(
                `/XML/st.reception.load/get_contenu_one_reception`, data
            )

            let Fournisseur = { data: {} }
            if (Reception.data.t != 'op') {
                Fournisseur = await BaseService.post(
                    `/XML/ap.fr.load/get_one_fr`, { 'idfr': Reception.data?.receptions?.[0]?.idfr }
                )
            }
            if (Reception.data.receptions[0]) {

                Reception.data.receptions[0]['utilisateur'] = Reception.data.receptions[0]['nom'] + ' ' + Reception.data.receptions[0]['prenom']
                if(Reception.data.t != 'op'){
                Reception.data.receptions[0]['id_co'] = ' CO-' + Reception.data.receptions[0]['id_co']
                }else{
                    Reception.data.receptions[0]['id_co'] = ' OP-' + Reception.data.receptions[0]['idop']
                }
            }
            return { Reception: Reception.data, content: content.data, Fournisseur: Fournisseur.data }
        } catch (error) {
            throw new Error('Failed to fetch Receptions')
        }
    }
)



export const fetcAutresCharges = createAsyncThunk(
    'Receptions/fetchautrescharges',
    async () => {

        try {
            const autresCharges = await BaseService.post(
                `/XML/st.reception.load/get_autres_chares`, {}
            )

            return autresCharges.data
        } catch (error) {
            throw new Error('Failed to fetch Receptions')
        }
    }
)







export const createReception = createAsyncThunk(
    'reception/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.reception.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(-1)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)



export const editReception = createAsyncThunk('receptions/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/receptions/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/receptions')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const deleteReception = createAsyncThunk(
    'reception/delete',
    async (payload) => {
        const { id, setReceptionAdded } = payload

        try {
            const response = await BaseService.post('/XML/st.reception.send/delete', { 'id': id })
            if (response.status === 200) {

                popNotification(creation?.success)
                setReceptionAdded(true)
            }
        } catch (err) {
            console.log({ err })
            

            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.receptions.data
export const selectIsLoading = (state) => state.receptions.isLoading
export const selectError = (state) => state.receptions.error
export const selectIsDeleted = (state) => state.receptions.isDeleted
export const selectCurrentPage = (state) => state.receptions.currentPage
export const startLoading = receptionsSlice.actions.startLoading
export const stopLoading = receptionsSlice.actions.stopLoading
export default receptionsSlice.reducer
