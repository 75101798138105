export function updateKeys(array, keyMapping) {
    return array.map(obj => {
        const updatedObj = { ...obj,eventColor:"blue" };

        for (let key in keyMapping) {
            if (updatedObj.hasOwnProperty(key)) {
                updatedObj[keyMapping[key]] = updatedObj[key];
                delete updatedObj[key];
            }
        }

        return updatedObj;
    });
}