import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const effetsFournisseursSlice = createSlice({
    name: 'effetsFournisseurs',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsEffetsFournisseurs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsEffetsFournisseurs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsEffetsFournisseur = action.payload
            })
            .addCase(fetchDetailsEffetsFournisseurs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchEffetsFournisseurs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchEffetsFournisseurs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchEffetsFournisseurs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createEffetsFournisseur.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createEffetsFournisseur.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createEffetsFournisseur.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })


            .addCase(payEffetsFournisseur.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(payEffetsFournisseur.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(payEffetsFournisseur.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })






            .addCase(deleteEffetsFournisseur.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteEffetsFournisseur.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteEffetsFournisseur.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchEffetsFournisseurs = createAsyncThunk(
    'effetsFournisseurs/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/tr.ec.fr.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch effetsFournisseurs')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsEffetsFournisseurs = createAsyncThunk(
    'EffetsFournisseurs/fetchDetails',
    async (id) => {
        const data = { 'idec': id.id, 'type_ec': 'c' }
        try {
            const EffetsFournisseur = await BaseService.post(
                `/XML/tr.ec.load/get_one_ec`, data
            )
            const Fournisseur = await BaseService.post(
                `/XML/ap.fr.load/get_one_fr`, { 'idfr': EffetsFournisseur.data[0].idpp }
            )
            const etat = {
                '0': 'À payer',
                '1': "Payer",
                '6': 'Annuler'


            }

            if (EffetsFournisseur.data[0]) {
                EffetsFournisseur.data[0]['utilisateur'] = EffetsFournisseur.data[0]['nom'] + ' ' + EffetsFournisseur.data[0]['prenom']
                EffetsFournisseur.data[0]['etat'] = etat[EffetsFournisseur.data[0]['etat']]

                EffetsFournisseur.data[0]['num'] = 'ECC' + '-' + EffetsFournisseur.data[0]['id']
                EffetsFournisseur.data[0]['MT'] = formatToDH(EffetsFournisseur.data[0]['MT'])
            }

            return { EffetsFournisseur: EffetsFournisseur.data[0], fournisseur: Fournisseur.data }
        } catch (error) {
            throw new Error('Failed to fetch EffetsFournisseurs')
        }
    }
)
export const createEffetsFournisseur = createAsyncThunk(
    'effetsFournisseurs/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ec.send/add_ec', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)

export const editEffetsFournisseur = createAsyncThunk('effetsFournisseurs/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/effetsFournisseurs/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/effetsFournisseurs')
        }
    } catch (err) {
        console.log({ err })

        popNotification(edit?.error)
    }
})

export const deleteEffetsFournisseur = createAsyncThunk(
    'effetsFournisseur/delete',
    async (payload) => {
        const { data, setEffetsFournisseurAdded } = payload

        try {
            const response = await BaseService.post('/XML/tr.ec.send/annuler_ec', data)
            if (response.status === 200) {
                popNotification(creation?.success)
                setEffetsFournisseurAdded(true)
            }
        } catch (err) {
            console.log({ err })
            popNotification(creation?.error)
        }
    }
)






export const payEffetsFournisseur = createAsyncThunk(
    'effetsFournisseurs/encaisser',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ec.send/encaisser_ec_f', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            setSubmitting(false)
            popNotification(creation?.error)
        }
    }
)


export const selectData = (state) => state.effetsFournisseurs.data
export const selectIsLoading = (state) => state.effetsFournisseurs.isLoading
export const selectError = (state) => state.effetsFournisseurs.error
export const selectIsDeleted = (state) => state.effetsFournisseurs.isDeleted
export const selectCurrentPage = (state) => state.effetsFournisseurs.currentPage
export default effetsFournisseursSlice.reducer
