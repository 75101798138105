import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const recrutementsSlice = createSlice({
    name: 'recrutements',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsRecrutements: (state) => {
            if (state.detailsRecrutements == null) {
                state.detailsRecrutements = ''
            }else{
                state.detailsRecrutements = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsRecrutements.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsRecrutements.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.detailsRecrutements = action.payload
        })
        .addCase(fetchDetailsRecrutements.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })



        
        .addCase(fetchCandidatRecrutements.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchCandidatRecrutements.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.candidatsRecrutements = action.payload
        })
        .addCase(fetchCandidatRecrutements.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })




            .addCase(fetchRecrutements.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchRecrutements.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchRecrutements.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createRecrutement.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createRecrutement.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createRecrutement.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteRecrutement.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteRecrutement.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteRecrutement.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchRecrutements = createAsyncThunk(
    'recrutements/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/rh.recrutement.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch recrutements')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsRecrutements = createAsyncThunk(
    'Recrutements/fetchDetails',
    async (id) => {
        const data = {'id':id.id}
        try {
            const Recrutement = await BaseService.post(
                `/XML/rh.recrutement.load/get_one_recrutement`,data
            )
            

            return Recrutement.data
        } catch (error) {
            throw new Error('Failed to fetch Recrutements')
        }
    }
)

// Async thunk action to fetch details from the API
export const fetchCandidatRecrutements = createAsyncThunk(
    'fetchcandidat/fetchDetails',
    async (id) => {
        const data = {'id':id.id}
        try {
            const Recrutement = await BaseService.post(
                `/XML/rh.recrutement.load/candidat_recrutement`,data
            )
            const calenderData = []
            Recrutement.data.map((item)=>{
  
                calenderData.push({
                    eventColor:"blue",
                    id:item.id,
                    title : item.nom+ ' '+item.prenom,
                    start : item.rendez_vous,
                    end : item.fin_rendez_vous,
                })
            })

            return calenderData
        } catch (error) {
            throw new Error('Failed to fetch Recrutements')
        }
    }
)

export const editCandidatRecrutement = createAsyncThunk(
    'recrutements/post',
    async (data) => {
       
        try {
            const response = await BaseService.post('/XML/rh.recrutement.send/m_candidat',data )
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)



export const createRecrutement = createAsyncThunk(
    'recrutements/post',
    async (payload) => {
        const { data, formik, navigate,setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/rh.recrutement.send/ajouter', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editRecrutement = createAsyncThunk('recrutements/edit', 
async (payload) => {
    const { data, formik, navigate,setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/rh.recrutement.send/modifier', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(creation?.error)
    }
})



export const candidtatRecrutement = createAsyncThunk('recrutements/edit', 
async (payload) => {
    const { data, formik, navigate,setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/rh.recrutement.send/add_candidat', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(creation?.error)
    }
})



export const deleteRecrutement = createAsyncThunk(
    'recrutement/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/recrutements/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.recrutements.data
export const selectIsLoading = (state) => state.recrutements.isLoading
export const selectError = (state) => state.recrutements.error
export const selectIsDeleted = (state) => state.recrutements.isDeleted
export const selectCurrentPage = (state) => state.recrutements.currentPage
export const resetDetailsRecrutements = recrutementsSlice.actions.resetDetailsRecrutements
export default recrutementsSlice.reducer
