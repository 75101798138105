import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const effetsClientsSlice = createSlice({
    name: 'effetsClients',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        restetDeailsEffetsClients: (state) => {

            if (state.detailsEffetsClients == null) {
                state.detailsEffetsClients = ''
            } else {
                state.detailsEffetsClients = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsEffetsClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsEffetsClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsEffetsClients = action.payload
            })
            .addCase(fetchDetailsEffetsClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchEffetsClients.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchEffetsClients.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchEffetsClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createEffetsClient.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createEffetsClient.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createEffetsClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteEffetsClient.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteEffetsClient.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteEffetsClient.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchEffetsClients = createAsyncThunk(
    'effetsClients/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/tr.ec.cl.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch effetsClients')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsEffetsClients = createAsyncThunk(
    'EffetsClients/fetchDetails',
    async (id) => {
        const data = { 'idec': id.id, 'type_ec': 'd' }
        try {
            const EffetsClient = await BaseService.post(
                `/XML/tr.ec.load/get_one_ec`, data
            )
            const Client = await BaseService.post(
                `/XML/ve.cl.load/get_one_cl`, { 'idcl': EffetsClient.data[0].idpp }
            )


            const etat = {
                '0': 'En portefeuille',
                '1': "À l'encaissement",
                '2': 'Encaisser',
                '4': "Mise à l'Escompte",
                '5': 'Escompter',
                '6': 'Annuler',
                '7': 'Endosser'


            }
            if (EffetsClient.data[0] && typeof id.side !== 'undefined') {
                EffetsClient.data[0]['utilisateur'] = EffetsClient.data[0]['nom'] + ' ' + EffetsClient.data[0]['prenom']
                EffetsClient.data[0]['etat'] = etat[EffetsClient.data[0]['etat']]

                EffetsClient.data[0]['num'] = 'ECD' + '-' + EffetsClient.data[0]['id']
                EffetsClient.data[0]['MT'] = formatToDH(EffetsClient.data[0]['MT'])
            }




            return { EffetsClient: EffetsClient.data[0], Client: Client.data }
        } catch (error) {
            throw new Error('Failed to fetch EffetsClients')
        }
    }
)
export const createEffetsClient = createAsyncThunk(
    'effetsClients/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ec.send/add_ec', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const endosserEffetsClient = createAsyncThunk('effetsClients/endosser',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ec.send/endosser', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)


export const escompteEffetsClient = createAsyncThunk('effetsClients/escompte',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ec.send/escompte', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)



export const confEscompteEffetsClient = createAsyncThunk('effetsClients/escompte',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ec.send/conf_escompte', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)
export const encaisserEffetsClient = createAsyncThunk('effetsClients/endosser',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            data.type = 'c'
            const response = await BaseService.post('/XML/tr.ec.send/encaisser', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)




export const confirmerEffetsClient = createAsyncThunk('effetsClients/confirmer',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            data.type = 'c'
            const response = await BaseService.post('/XML/tr.ec.send/conf_encaisser', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)



export const deleteEffetsClient = createAsyncThunk(
    'effetsClient/delete',
    async (payload) => {
        const { data, setEffetsClientAdded } = payload

        try {
            const response = await BaseService.post('/XML/tr.ec.send/annuler_ec', data)
            if (response.status === 200) {

              
                popNotification(creation?.success)
                setEffetsClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
         
            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.effetsClients.data
export const selectIsLoading = (state) => state.effetsClients.isLoading
export const selectError = (state) => state.effetsClients.error
export const selectIsDeleted = (state) => state.effetsClients.isDeleted
export const selectCurrentPage = (state) => state.effetsClients.currentPage
export const restetDeailsEffetsClients = effetsClientsSlice.actions.restetDeailsEffetsClients
export default effetsClientsSlice.reducer
