import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { signedIn } = useSelector((state) => state.auth.session)
    const token = useSelector((state) => state.auth.session.token)
    // const token = localStorage.getItem('token')

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            console.log(resp.data)
            if (resp.data) {
                const { token } = resp.data
                const { autority } = resp.data
                const { paid } = resp.data
                const { headerReact } = resp.data
                const { expire } = resp.data
                const { nom } = resp.data
                localStorage.setItem('authToken', token);
                dispatch(onSignInSuccess(token))
                // if (resp.data.user) {

                dispatch(
                    setUser(
                        {
                            avatar: '',
                            userName: nom,
                            authority: autority,
                            email: '',
                            firstRoute: headerReact,
                            paid: paid,
                            expire: expire,
                        }
                    )
                )
                // }
                // const redirectUrl = query.get(REDIRECT_URL_KEY)
                const redirectUrl = headerReact
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: "identifiant ou mot de passe incorrect",
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            console.log(resp.data)
            if (resp.data) {
                const { token } = resp.data
                const { autority } = resp.data
                const { headerReact } = resp.data
                const { paid } = resp.data
                const { expire } = resp.data
                const { nom } = resp.data
                localStorage.setItem('authToken', token);
                localStorage.setItem('testDatabase', 'true');
                dispatch(onSignInSuccess(token))
                // if (resp.data.user) {

                dispatch(
                    setUser(
                        {
                            avatar: '',
                            userName: nom,
                            authority: autority,
                            email: '',
                            firstRoute: headerReact,
                            paid: paid,
                            expire: expire,
                        }
                    )
                )
                // }
                // const redirectUrl = query.get(REDIRECT_URL_KEY)
                const redirectUrl = headerReact
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        localStorage.removeItem('authToken');
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {

        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
