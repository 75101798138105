import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const articlesSlice = createSlice({
    name: 'articles',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {

        resetDetailsArticles: (state) => {
            if (state.detailsArticles == null) {
                state.detailsArticles = ''
            } else {
                state.detailsArticles = null
            }
        },
        startLoading: state => {
            state.isLoading = true;
        },
        stopLoading: state => {
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchArticles.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchArticles.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchArticles.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createArticle.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsArticles.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsArticles.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsArticles = action.payload
            })
            .addCase(fetchDetailsArticles.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })








            .addCase(createArticle.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createArticle.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })


            .addCase(editSd.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(editSd.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })


            .addCase(importArticle.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(importArticle.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(importArticle.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchArticles = createAsyncThunk(
    'articles/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        const stockable = payload?.stockable || ''
        const statut_f_f = payload?.statut_f_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/st.produits.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}&stockable=${stockable}&statut_f_f=${statut_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch articles')
        }
    }
)


export const createArticle = createAsyncThunk(
    'articles/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.produits.send/dataAjouter', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsArticles = createAsyncThunk(
    'Articles/fetchDetails',
    async (id) => {
        const data = { 'idp': id.id }
       
            let date_d_f = id?.date_d_f || dayjs()
            let date_f_f = id?.date_f_f || dayjs().subtract(1, 'years')
            if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
                date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
                date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            } else if (dayjs(date_d_f).isValid()) {
                date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
                date_f_f = date_d_f;
            } else if (dayjs(date_f_f).isValid()) {
                date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
                date_d_f = date_f_f;
            }
        
        try {
            const Article = await BaseService.post(
                `/XML/st.produits.load/get_one_product`, data
            )
            const distribution = await BaseService.post(
                `/XML/st.produits.load/get_distri_prod`, data
            )
            const historiquedata = await BaseService.get(
                `/XML/st.produits.load/historique?idp=${data.idp}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )
            const cout = await BaseService.get(
                `/XML/st.stat.load/reception/cout/oneArticle?idp=${data.idp}&startDate=${date_d_f}&endDate=${date_f_f}`
            )
            let historique = []
            if (historiquedata.status === 200) {
                historique = historiquedata.data
            }

            const objet = {
                'M': 'Marchandise',
                'C': 'Consommable',
                'S': 'Autre',
                'MP': 'Matière première',
                'PF': 'Produit fini'
            }

            if (Article.data[0] && typeof id.side !== 'undefined') {
                Article.data[0]['catégorie'] = objet[Article.data[0]['catégorie']]
                Article.data[0]['CA'] = formatToDH(Article.data[0]['CA'])
                Article.data[0]['PA'] = formatToDH(Article.data[0]['PA'])
                Article.data[0]['PV'] = formatToDH(Article.data[0]['PV'])
            }



            return { Article: Article.data[0], distribution: distribution.data, historique: historique ,cout:cout}
        } catch (error) {
            throw new Error('Failed to fetch Articles')
        }
    }
)

export const editArticle = createAsyncThunk('articles/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/st.produits.send/dataModifier', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(creation?.error)
    }
}
)

export const importArticle = createAsyncThunk(
    'article/import',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post(`/XML/st.produits.send/uploadcsv`, data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(edit?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(edit?.error)
        }
    }
)








export const editSd = createAsyncThunk('sd/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('XML/st.produits.send/editSd', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(creation?.error)
    }
}
)

export const selectData = (state) => state.articles.data
export const selectIsLoading = (state) => state.articles.isLoading
export const selectError = (state) => state.articles.error
export const selectIsDeleted = (state) => state.articles.isDeleted
export const selectCurrentPage = (state) => state.articles.currentPage
export const resetDetailsArticles = articlesSlice.actions.resetDetailsArticles
export const startLoading = articlesSlice.actions.startLoading
export const stopLoading = articlesSlice.actions.stopLoading
export default articlesSlice.reducer
