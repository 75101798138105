import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.baseUrl,
})


BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        let accessToken = localStorage.getItem('authToken');
        let testDatabase = localStorage.getItem('testDatabase');

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }
        
        if (accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
        }
        if (testDatabase) {
            config.headers['test-database'] = testDatabase
        }


        return config;
    },
    (error) => {
        console.error('Request Error:', error);
        return Promise.reject(error);
    }
)

BaseService.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess());
        }

        // Include status code in the rejected promise
        return Promise.reject({
            message: error.response.data?.error || JSON.stringify(error.response.data),
            status: response?.status
        });
    }
)

export default BaseService
