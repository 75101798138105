import Decimal from "decimal.js";

function groupByPrefix(arr, length, plan) {
    const result = {};

    arr.forEach(item => {
        const key = item.compte.substring(0, length);
        if (!result[key]) {
            result[key] = { 
                compte: key, 
                description: plan[key] ? `${key} ${plan[key]}` : key,
                solde: new Decimal(0), 
                subRows: [] 
            };
        }
        result[key].solde = result[key].solde.plus(new Decimal(item?.solde || 0));
        result[key].subRows.push(item);
    });

    for (let key in result) {
        result[key].solde = result[key].solde.toFixed(2);
    }

    return Object.values(result);
}

export function hierarchicalGroup(data, plan, depth = 1) {
    if (depth > 6) return data;

    const grouped = groupByPrefix(data, depth, plan);
    grouped.forEach(group => {
        group.subRows = hierarchicalGroup(group.subRows, plan, depth + 1);
    });

    return grouped.filter(group => group.subRows.length > 0);
}