
export function formatDateTime(dateString) {
    const date = new Date(dateString);
    const formattedDateTime = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    return formattedDateTime;
  }

export function formatDate(dateString) {
  const date = new Date(dateString);
  const formattedDateTime = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  return formattedDateTime;
}

export function getMonthYearInFrench(date) {
  // Define month names in French
  const monthsInFrench = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  // Convert input date to JavaScript Date object
  const dateObj = new Date(date);

  // Extract the month and year from the date
  const month = monthsInFrench[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  return `${month} ${year}`;
}