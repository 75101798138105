import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const livraisonsRetoursSlice = createSlice({
    name: 'livraisonsRetours',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsLivraisonsRetours.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsLivraisonsRetours.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.data = action.payload
        })
        .addCase(fetchDetailsLivraisonsRetours.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchLivraisonsRetours.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchLivraisonsRetours.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchLivraisonsRetours.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createLivraisonsRetour.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createLivraisonsRetour.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createLivraisonsRetour.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteLivraisonsRetour.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteLivraisonsRetour.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteLivraisonsRetour.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchLivraisonsRetours = createAsyncThunk(
    'livraisonsRetours/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/st.livraison.retour.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch livraisonsRetours')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsLivraisonsRetours = createAsyncThunk(
    'LivraisonsRetours/fetchDetails',
    async (id) => {
        const data = {'idr':id.id}
        try {
            const LivraisonsRetour = await BaseService.post(
                `/XML/st.livraison.retour.load/get_one_retour`,data
            )
            const contenue = await BaseService.post(
                `/XML/st.livraison.retour.load/get_contenu_one_retour`,data
            )
         const idcl = LivraisonsRetour?.data?.[0]?.['idcl'] || LivraisonsRetour.data[0]['idclient']
            const Client = await BaseService.post(
                `/XML/ve.cl.load/get_one_cl`,{'idcl':idcl}
            )
if (LivraisonsRetour.data[0]) {
    LivraisonsRetour.data[0]['id'] = 'RL-' + LivraisonsRetour.data[0]['id']
    LivraisonsRetour.data[0]['id_source'] = 'L-' + LivraisonsRetour.data[0]['id_source']
    LivraisonsRetour.data[0]['utilisateur'] = LivraisonsRetour.data[0]['nom'] + ' ' + LivraisonsRetour.data[0]['prenom']
}
            return {LivraisonsRetour: LivraisonsRetour.data, contenue: contenue.data, Client: Client.data}
        } catch (error) {
            throw new Error('Failed to fetch LivraisonsRetours')
        }
    }
)
export const createLivraisonsRetour = createAsyncThunk(
    'livraisonsRetours/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.livraison.retour.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(-1)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editLivraisonsRetour = createAsyncThunk('livraisonsRetours/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/livraisonsRetours/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/livraisonsRetours')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const deleteLivraisonsRetour = createAsyncThunk(
    'livraisonsRetour/delete',
    async (payload) => {
        const { id,setlivraisonsRetoursAded } = payload
        try {
            const response = await BaseService.post('/XML/st.livraison.retour.send/delete', {id})
            if (response.status === 200) {
                popNotification(creation?.success)
                setlivraisonsRetoursAded(true)
            }
        } catch (err) {
            console.log({ err })
            
            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.livraisonsRetours.data
export const selectIsLoading = (state) => state.livraisonsRetours.isLoading
export const selectError = (state) => state.livraisonsRetours.error
export const selectIsDeleted = (state) => state.livraisonsRetours.isDeleted
export const selectCurrentPage = (state) => state.livraisonsRetours.currentPage

export default livraisonsRetoursSlice.reducer
