import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { notificationConfig } from './constants'
import { formatDateTime } from '../../functions/formatDate'

// Redux Toolkit slice
const tableauBordsSlice = createSlice({
    name: 'tableauBords',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableauBords.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchTableauBords.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchTableauBords.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

    },
})

// Async thunk action to fetch data from the API
export const fetchTableauBords = createAsyncThunk(
    'tableauBords/fetch',
    async ({ startDate, endDate }) => {
        // Check for existence of startDate and endDate
        if (!startDate || !endDate) {
            throw new Error('Missing startDate or endDate');
        }

        try {
            const promises = [
                BaseService.get(`/XML/tableauBord.load?startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`),
                BaseService.get(`/XML/st.stat.load/reception/cout?startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`),
                BaseService.get(`/XML/ve.stat.load/ventes/tauxConversion?startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`),
                BaseService.get(`/XML/compta.stat.load/margeExploitation?startDate=${formatDateTime(startDate)}&endDate=${formatDateTime(endDate)}`),
               
            ];

            const results = await Promise.allSettled(promises);

            const data = results.map((result, index) => {
                if (result.status === 'fulfilled') {
                    return result.value.data;
                } else {
                    console.error(`Promise ${index + 1} rejected with ${result.reason}`);
                    return null;
                }
            });

            return {
                data: data[0],
                cout: data[1],
                coversion: data[2],
                marge: data[3],
            };
        } catch (error) {
            throw new Error('Failed to fetch tableauBords');
        }
    }
);


export const selectData = (state) => state.tableauBords.data
export const selectIsLoading = (state) => state.tableauBords.isLoading
export const selectError = (state) => state.tableauBords.error

export default tableauBordsSlice.reducer
