import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import formatToDH from 'functions/dhformat'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const banquesSlice = createSlice({
    name: 'banques',
    initialState: {
        data: [],
        nomsBanques: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsBanques: (state) => {
            if (state.detailsBanques == null) {
                state.detailsBanques = ''
            } else {
                state.detailsBanques = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDatasBanques.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDatasBanques.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.dataForm = action.payload
            })
            .addCase(fetchDatasBanques.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchDetailsBanques.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsBanques.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsBanques = action.payload
            })
            .addCase(fetchDetailsBanques.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchBanques.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchBanques.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchBanques.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchNomsBanques.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchNomsBanques.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.nomsBanques = action.payload
            })
            .addCase(fetchNomsBanques.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createBanque.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createBanque.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createBanque.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })

            .addCase(paypaie.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(paypaie.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(paypaie.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })




            .addCase(deleteBanque.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteBanque.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteBanque.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
            .addCase(payAvance.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(payAvance.fulfilled, (state) => {
                state.isLoading = false

            })
            .addCase(payAvance.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message

            })


            .addCase(transfertTreso.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(transfertTreso.fulfilled, (state) => {
                state.isLoading = false

            })
            .addCase(transfertTreso.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message

            })

    },
})

// Async thunk action to fetch data from the API
export const fetchBanques = createAsyncThunk(
    'banques/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        const type_f = payload?.type_f || ''
        const idpp_f = payload?.idpp_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/tr.ba.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}&type_f=${type_f}&idpp_f=${idpp_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch banques')
        }
    }
)

export const fetchNomsBanques = createAsyncThunk(
    'nomsBanques/fetch',
    async () => {

        try {
            const response = await BaseService.get(
                `/XML/tr.ba.load/get_all_compte`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch banques')
        }
    }
)

export const fetchDatasBanques = createAsyncThunk(
    'datasBanques/fetch',
    async () => {

        try {
            const response = await BaseService.post(
                `/XML/tr.ba.load/get_data`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch banques')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsBanques = createAsyncThunk(
    'Banques/fetchDetails',
    async (id) => {
        const data = { 'idt': id.id, 'compte': id.filter, 'type': 'aaaa' }

        try {
            let pp
            let type
            const Banque = await BaseService.post(
                `/XML/tr.ba.load/get_one_t`, data
            )

            const compte = await BaseService.post(
                `/XML/tr.ba.load/get_nom_table_compte`, { 'id': id.filter }
            )
            if (Banque.data[0]['bénéficiaire'].split('_')[0] == 'C') {

                pp = await BaseService.post(
                    `/XML/ve.cl.load/get_one_cl`, { 'idcl': Banque.data[0]['bénéficiaire'].split('_')[1] }
                )
                type = 'Client'
            } else if (Banque.data[0]['bénéficiaire'].split('_')[0] == 'F') {

                pp = await BaseService.post(
                    `/XML/ap.fr.load/get_one_fr`, { 'idfr': Banque.data[0]['bénéficiaire'].split('_')[1] }
                )

                type = 'fournisseur'
            } else if (Banque.data[0]['bénéficiaire'].split('_')[0] == 'AU') {

                pp = await BaseService.post(
                    `/XML/tr.autrepp.load/get_one`, { 'idau': Banque.data[0]['bénéficiaire'].split('_')[1] }
                )

                type = 'Partie prenante'
            } else {
                pp = {}
                type = "employe"
            }
            if (Banque.data[0]) {
                const dic_type = {
                    'E': 'Encaissement',
                    'D': 'Decaissement'
                }
                const objNature = {
                    'esp': "Espèce",
                    'c': "Chèque",
                    'v': "Virement",
                    'p': "Prélèvement",
                    'vr': 'Versement',
                }

                Banque.data[0]['type'] = dic_type[Banque.data[0]['type']]
                Banque.data[0]['operateur'] = Banque.data[0]['nom'] + ' ' + Banque.data[0]['prenom']
                Banque.data[0]['MT'] = formatToDH(Banque.data[0]['MT'])
                Banque.data[0]['newNature'] = objNature[Banque.data[0]['newNature']]
            }

            return { Banque: Banque.data, pp: pp.data, type: type, compte: compte.data }
        } catch (error) {
            throw new Error('Failed to fetch Banques')
        }
    }
)
export const createBanque = createAsyncThunk(
    'banques/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.ba.send/typeAdd_transaction', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)

export const editBanque = createAsyncThunk('banques/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/tr.ba.send/m_date_tr', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})

export const deleteBanque = createAsyncThunk(
    'banque/delete',
    async (payload) => {
        const { data, setBanqueAdded } = payload

        try {
            const response = await BaseService.post('/XML/tr.ba.send/delete_tr_tr_ba', data)
            if (response.status === 200) {


                popNotification(creation?.success)
                setBanqueAdded(true)
            }
        } catch (err) {
            console.log({ err })



            popNotification(creation?.error)
        }
    }
)




export const payAvance = createAsyncThunk('paie/avance', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/tr.ba.send/pay_av_salaire', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})

export const transfertTreso = createAsyncThunk('transfert/reso', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/tr.ba.send/typeTransfert', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})
export const paypaie = createAsyncThunk('paie/paie', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const fiches = data?.fiches
        for (let i = 0; i < fiches.length; i++) {
            const element = fiches[i];
            const id_paie = data?.id_paie
            const id_fiche = element?.id_fiche
            const id_employe = element?.id_employe
            const type = element?.type
            const compte = element?.compte?.split('_')[1]
            const ref = element?.ref
            const date_valeur = element?.date_valeur
            await BaseService.post('/XML/tr.ba.send/pay_paie', { id_paie: id_paie, id_fiche: id_fiche, id_employe: id_employe, type: type, compte: compte, ref: ref, date_valeur: date_valeur })

        }

        resetForm()
        setSubmitting(false)
        popNotification(creation?.success)
        navigate('/Tresorerie/BanqueCaisse')

    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})

export const selectData = (state) => state.banques.data
export const selectIsLoading = (state) => state.banques.isLoading
export const selectError = (state) => state.banques.error
export const selectIsDeleted = (state) => state.banques.isDeleted
export const selectCurrentPage = (state) => state.banques.currentPage
export const resetDetailsBanques = banquesSlice.actions.resetDetailsBanques
export const startLoading = banquesSlice.actions.startLoading
export const stopLoading = banquesSlice.actions.stopLoading
export default banquesSlice.reducer
