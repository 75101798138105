import { getThemeConfig } from 'configs/theme.config'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs';
import BaseService from 'services/BaseService'
import {
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_STACKED_SIDE,
    NAV_MODE_TRANSPARENT,
    NAV_MODE_LIGHT,
    NAV_MODE_DARK,
    NAV_MODE_THEMED,
    MODE_DARK,
    MODE_LIGHT,
    LAYOUT_TYPE_DECKED,
} from 'constants/theme.constant'


export const fetchMode = createAsyncThunk(
    'theme/fetchMode',
    async (_, thunkAPI) => {
      try {
        
        const data = await BaseService.get('/connection/XML/react.theme/getMode');
        return data.data;  // supposant que l'API retourne un objet avec une propriété 'mode'
      } catch (error) {
        throw new Error('Failed to fetch Mode')
      }
      
    }
  );



const themeConfig = await getThemeConfig()

const initialNavMode = () => {
    if (
        themeConfig.layout.type === LAYOUT_TYPE_MODERN &&
        themeConfig.navMode !== NAV_MODE_THEMED
    ) {
        return NAV_MODE_TRANSPARENT
    }

    return themeConfig?.navMode
}

const initialState = {
    themeColor: themeConfig?.themeColor,
    direction: themeConfig?.direction,
    mode: themeConfig?.mode,
    primaryColorLevel: themeConfig?.primaryColorLevel,
    panelExpand: themeConfig?.panelExpand,
    navMode: initialNavMode(),
    layout: themeConfig?.layout,
}

const availableNavColorLayouts = [
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
]

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setDirection: (state, action) => {
            state.direction = action.payload
        },
        setMode: (state, action) => {
            const availableColorNav = availableNavColorLayouts.includes(
                state.layout.type
            )

            if (
                availableColorNav &&
                action.payload === MODE_DARK &&
                state.navMode !== NAV_MODE_THEMED
            ) {
                state.navMode = NAV_MODE_DARK
            }
            if (
                availableColorNav &&
                action.payload === MODE_LIGHT &&
                state.navMode !== NAV_MODE_THEMED
            ) {
                state.navMode = NAV_MODE_LIGHT
            }
            state.mode = action.payload
        },
        setLayout: (state, action) => {
            state.cardBordered = action.payload === LAYOUT_TYPE_MODERN
            if (action.payload === LAYOUT_TYPE_MODERN) {
                state.navMode = NAV_MODE_TRANSPARENT
            }

            const availableColorNav = availableNavColorLayouts.includes(
                action.payload
            )

            if (availableColorNav && state.mode === MODE_LIGHT) {
                state.navMode = NAV_MODE_LIGHT
            }

            if (availableColorNav && state.mode === MODE_DARK) {
                state.navMode = NAV_MODE_DARK
            }

            state.layout = {
                ...state.layout,
                ...{ type: action.payload },
            }
        },
        setPreviousLayout: (state, action) => {
            state.layout.previousType = action.payload
        },
        setSideNavCollapse: (state, action) => {
            state.layout = {
                ...state.layout,
                ...{ sideNavCollapse: action.payload },
            }
        },
        setNavMode: (state, action) => {
            if (action.payload !== 'default') {
                state.navMode = action.payload
            } else {
                if (state.layout.type === LAYOUT_TYPE_MODERN) {
                    state.navMode = NAV_MODE_TRANSPARENT
                }

                const availableColorNav = availableNavColorLayouts.includes(
                    state.layout.type
                )

                if (availableColorNav && state.mode === MODE_LIGHT) {
                    state.navMode = NAV_MODE_LIGHT
                }

                if (availableColorNav && state.mode === MODE_DARK) {
                    state.navMode = NAV_MODE_DARK
                }
            }
        },
        setPanelExpand: (state, action) => {
            state.panelExpand = action.payload
        },
        setThemeColor: (state, action) => {
            state.themeColor = action.payload
        },
        setThemeColorLevel: (state, action) => {
            state.primaryColorLevel = action.payload
        },
    },



    extraReducers: (builder) => {
        builder
          .addCase(fetchMode.fulfilled, (state, action) => {
            const mode = action.payload;
            if (mode === MODE_DARK || mode === MODE_LIGHT) {
              state.mode = mode;
              // Mettez à jour navMode en fonction de mode si nécessaire
            }
          })
          .addCase(fetchMode.rejected, (state, action) => {
            console.error('Erreur lors de la récupération du mode:', action.error.message);
          });
      },
})

export const {
    setDirection,
    setMode,
    setLayout,
    setSideNavCollapse,
    setNavMode,
    setPanelExpand,
    setThemeColor,
    setThemeColorLevel,
    setPreviousLayout,
} = themeSlice.actions

export default themeSlice.reducer
