import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import { FaCheck, FaTimes } from 'react-icons/fa';
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const achatsSlice = createSlice({
    name: 'achats',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsAchat: (state) => {
            if (state.detailsAchat == null) {
                state.detailsAchat = ''
            } else {
                state.detailsAchat = null
            }
        },
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAchats.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchAchats.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchAchats.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchContenuNLivreAchat.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchContenuNLivreAchat.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.contenuNLivre = action.payload
            })
            .addCase(fetchContenuNLivreAchat.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchDetailsAchats.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsAchats.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsAchat = action.payload
            })
            .addCase(fetchDetailsAchats.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createAchat.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createAchat.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createAchat.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })




            .addCase(confAchat.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(confAchat.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(confAchat.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteAchat.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteAchat.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteAchat.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchAchats = createAsyncThunk(
    'achats/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }

        try {
            const response = await BaseService.get(
                `/XML/ap.co.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch achats')
        }
    }
)

// Async thunk action to fetch details from the API
export const fetchDetailsAchats = createAsyncThunk(
    'achats/fetchDetails',
    async (id) => {
        const data = { 'idc': id.id }
        try {
            const achat = await BaseService.post(
                `/XML/ap.co.load/get_one_commande`, data
            )
            const contenu = await BaseService.post(
                `/XML/ap.co.load/get_details_one_commande`, data
            )
            const Fournisseur = await BaseService.post(
                `/XML/ap.fr.load/get_one_fr`, { 'idfr': achat.data?.[0]?.idfr }
            )
            const actions = await BaseService.get(
                `/XML/info/getActions?type=idachats&id=${id.id}`
            )
            let facture = {}

            if (achat.data?.[0]?.id_facture) {

                facture = await BaseService.post(
                    `/XML/compta.factf.load/side_details_fact`, { 'idf': achat.data?.[0]?.id_facture }
                )

            }
            const st_c = { 'BC': 'Bon de commande', 'F': 'Facture', 'D': 'Besoin', 'AN': 'Annuler' }
            if (achat.data[0]) {
                achat.data[0]['statut_c'] = st_c[achat.data[0]['statut_c']]
                achat.data[0]['num'] = 'CO-' + achat.data[0]['id']
                achat.data[0]['utilisateur'] = achat.data[0]['nom'] + ' ' + achat.data[0]['prenom']
            }

            return { achat: achat.data?.[0], Fournisseur: Fournisseur.data, contenu: contenu.data, facture: facture.data || {}, actions: actions.data }
        } catch (error) {
            throw new Error('Failed to fetch achats')
        }
    }
)



export const fetchContenuNLivreAchat = createAsyncThunk(
    'Achats/fetchcontenuNlivre',
    async (id) => {
        try {
            const response = await BaseService.post(`/XML/ap.co.load/get_contenu_n_l_one_commande`, { 'idc': id })
            const Achat = await BaseService.post(
                `/XML/ap.co.load/get_one_commande`, { 'idc': id }
            )
            return { contenuNLivre: response.data, achat: Achat.data?.[0] }
        } catch (error) {
            throw new Error('Failed to fetch Achats')
        }
    }
)






export const createAchat = createAsyncThunk(
    'achats/post',
    async (payload) => {
        const { data, formik, navigate, setStateTimeline, stateTimeline } = payload
        const { resetForm, setSubmitting } = formik
        const newStateTimeLine = { ...stateTimeline }
        try {
            const response = await BaseService.post('/XML/ap.co.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Achats/Achats/add/' + response.data?.id)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)




export const confAchat = createAsyncThunk(
    'achats/conf',
    async (payload) => {
        const { data, setAded } = payload

        try {
            const response = await BaseService.post('/XML/ap.co.send/confirmer_ddp', data)
            if (response.status === 200) {
                // resetForm()
                popNotification(creation?.success)
                setAded(true)
                // navigate('/achats')
            }
        } catch (err) {
            console.log({ err })
            
            
            popNotification(creation?.error)
        }
    }

)

export const editAchat = createAsyncThunk('achats/edit',
    async (payload) => {
        const { data, formik, setAded } = payload
        const { resetForm, setSubmitting } = formik

        try {
            const response = await BaseService.post('/XML/ap.co.send/edit', data)
            if (response.status === 200) {

                setSubmitting(false)
                popNotification(creation?.success)
                setAded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    })

export const deleteAchat = createAsyncThunk(
    'achat/delete',
    async (payload) => {
        const { data, setAchatAdded } = payload

        try {
            const response = await BaseService.post('/XML/ap.co.send/delete', data)
            if (response.status === 200) {

              
                popNotification(creation?.success)
                setAchatAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
         
            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.achats.data
export const selectIsLoading = (state) => state.achats.isLoading
export const selectError = (state) => state.achats.error
export const selectIsDeleted = (state) => state.achats.isDeleted
export const selectCurrentPage = (state) => state.achats.currentPage
export const resetDetailsAchat = achatsSlice.actions.resetDetailsAchat
export const startLoading = achatsSlice.actions.startLoading
export const stopLoading = achatsSlice.actions.stopLoading
export default achatsSlice.reducer
