import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const crmsSlice = createSlice({
    name: 'crms',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            
            .addCase(editCrm.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(editCrm.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(editCrm.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
           
            
    },
})




export const editCrm = createAsyncThunk(
    'crms/post',
    async (payload) => {
        const { data, formik, navigate, setDataCrm } = payload
        const { resetForm, setSubmitting } = formik
      
        try {
            const response = await BaseService.post('/XML/info.send/crm', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setDataCrm({})
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)


export const selectData = (state) => state.crms.data
export const selectIsLoading = (state) => state.crms.isLoading
export const selectError = (state) => state.crms.error
export const selectIsDeleted = (state) => state.crms.isDeleted
export const selectCurrentPage = (state) => state.crms.currentPage
export default crmsSlice.reducer
