import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const transfertsSlice = createSlice({
    name: 'transferts',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsTransferts: (state) => {
            if (state.detailsTransferts == null) {
                state.detailsTransferts = ''
            } else {
                state.detailsTransferts = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsTransferts.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsTransferts.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.detailsTransferts = action.payload
        })
        .addCase(fetchDetailsTransferts.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchTransferts.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchTransferts.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchTransferts.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createTransfert.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createTransfert.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createTransfert.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(confTransfert.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(confTransfert.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(confTransfert.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })




            .addCase(deleteTransfert.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteTransfert.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(deleteTransfert.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchTransferts = createAsyncThunk(
    'transferts/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/st.transferts.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch transferts')
        }
    }
)

export const createTransfert = createAsyncThunk(
    'transferts/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.transferts.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Stocks/Transferts')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsTransferts = createAsyncThunk(
    'Transferts/fetchDetails',
    async (id) => {
        try {
            const Transfert = await BaseService.get(
                `/XML/st.transferts.load/details?id=${id.id}`
            )
            

            return Transfert.data
        } catch (error) {
            throw new Error('Failed to fetch Transferts')
        }
    }
)


export const editTransfert = createAsyncThunk(
    'transferts/edit',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.transferts.send/edit', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/Stocks/Transferts')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)


 export const confTransfert = createAsyncThunk('transferts/conf', async (payload) => {
        const { id, setTransfertAdded } = payload
        
        try {
            const response = await BaseService.post(`/XML/st.transferts.send/conf`, {id:id})
            if (response.status === 200) {
                
                popNotification(edit?.success)
                setTransfertAdded(true)
                
            }
        } catch (err) {
            console.log({ err })
            popNotification(edit?.error)
        }
    })



    
    export const deleteTransfert = createAsyncThunk('transferts/delete', async (payload) => {
        const { id, setTransfertAdded } = payload
        
        try {
            const response = await BaseService.post(`/XML/st.transferts.send/delete`, {id:id})
            if (response.status === 200) {
                
                popNotification(edit?.success)
                setTransfertAdded(true)
                
            }
        } catch (err) {
            console.log({ err })
            popNotification(edit?.error)
        }
    })

export const selectData = (state) => state.transferts.data
export const selectIsLoading = (state) => state.transferts.isLoading
export const selectError = (state) => state.transferts.error
export const selectIsDeleted = (state) => state.transferts.isDeleted
export const selectCurrentPage = (state) => state.transferts.currentPage
export const resetDetailsTransferts = transfertsSlice.actions.resetDetailsTransferts
export default transfertsSlice.reducer
