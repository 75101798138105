import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import formatToDH from 'functions/dhformat'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const chequesFrSlice = createSlice({
    name: 'chequesFr',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsChequesFr.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsChequesFr.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsChequesFr = action.payload
            })
            .addCase(fetchDetailsChequesFr.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchChequesFr.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchChequesFr.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchChequesFr.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })

            .addCase(addCheque.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(addCheque.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(addCheque.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })


            
            .addCase(deleteCheque.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(deleteCheque.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(deleteCheque.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })

    },
})

// Async thunk action to fetch data from the API
export const fetchChequesFr = createAsyncThunk(
    'chequesFr/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/tr.chequeFr.load?page=${page}&limit=${limit}&text=${search}&etat_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch chequesFr')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsChequesFr = createAsyncThunk(
    'ChequesFr/fetchDetails',
    async (id) => {
        const data = { 'id': id.id, 'side': id.side }
        try {
            const Cheque = await BaseService.get(
                `/XML/tr.chequeFr.load/details?id=${data.id}`
            )

            const objetat = {
                '0': 'À payer',
                '1': 'Payé',
            }

            Cheque.data.data['date_format'] = dayjs(Cheque.data.data.date).format('DD/MM/YYYY')
            Cheque.data.data['echeance_format'] = dayjs(Cheque.data.data.echeance).format('DD/MM/YYYY')
            Cheque.data.data['etat_format'] = objetat[Cheque.data.data.etat]
            Cheque.data.data['montant'] = formatToDH(Cheque.data.data.MT)
            
            return { Cheque: Cheque.data.data, fr: Cheque.data.fr }
        } catch (error) {
            throw new Error('Failed to fetch ChequesFr')
        }
    }
)

export const encaisserCheque = createAsyncThunk(
    'chequesFr/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.chequeFr.send/encaisser', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)

export const addCheque = createAsyncThunk(
    'chequesFr/add',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.chequeFr.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)










export const deleteCheque = createAsyncThunk(
    'cheque/delete',
    async (payload) => {
        const { data, setChequeAdded } = payload
        try {
            const response = await BaseService.post('/XML/tr.chequeFr.send/delete_tr_cheque', data)
            if (response.status === 200) {
                popNotification(creation?.success)
                setChequeAdded(true)
            }
        } catch (err) {
            console.log({ err })
            popNotification(creation?.error)
        }
    }
)




export const selectData = (state) => state.chequesFr.data
export const selectIsLoading = (state) => state.chequesFr.isLoading
export const selectError = (state) => state.chequesFr.error
export const selectIsDeleted = (state) => state.chequesFr.isDeleted
export const selectCurrentPage = (state) => state.chequesFr.currentPage

export default chequesFrSlice.reducer
