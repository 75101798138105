import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const journalsSlice = createSlice({
    name: 'journals',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchJournals.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchJournals.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchJournals.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createJournal.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createJournal.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createJournal.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteJournal.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteJournal.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteJournal.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchJournals = createAsyncThunk(
    'journals/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.compte_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/compta.jr.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch journals')
        }
    }
)

export const createJournal = createAsyncThunk(
    'journals/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/compta.jr.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/comptabilite/Journals')
            }
        } catch (err) {
            console.log({ err })

            setSubmitting(false)

            popNotification(creation?.error)
        }
    }
)

export const editJournal = createAsyncThunk('journals/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/journals/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/journals')
        }
    } catch (err) {
        console.log({ err })

        popNotification(edit?.error)
    }
})

export const deleteJournal = createAsyncThunk(
    'journal/delete',
    async ({ data,setFournisseurAdded }) => {
        console.log(data)
        try {
            const response = await BaseService.post('/XML/compta.jr.send/delete', data)
            popNotification(creation?.success)
            setFournisseurAdded(true)
            return true
        } catch (err) {
            popNotification(creation?.error)
            return
        }
    }
)

export const selectData = (state) => state.journals.data
export const selectIsLoading = (state) => state.journals.isLoading
export const selectError = (state) => state.journals.error
export const selectIsDeleted = (state) => state.journals.isDeleted
export const selectCurrentPage = (state) => state.journals.currentPage
export const startLoading = journalsSlice.actions.startLoading
export const stopLoading = journalsSlice.actions.stopLoading

export default journalsSlice.reducer
