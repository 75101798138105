import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const conditionsSlice = createSlice({
    name: 'conditions',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConditions.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchConditions.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchConditions.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(editCondition.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(editCondition.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(editCondition.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
           
            
    },
})

// Async thunk action to fetch data from the API
export const fetchConditions = createAsyncThunk(
    'conditions/fetch',
    async (payload) => {
        try {
            const response = await BaseService.post(
                `/XML/info/arr_info`,{'arr_info':'conditions_vente,conditions_achats,conditions_livaraison,conditions_reception,conditions_fact_c,conditions_avoir_c,'}
            )
            const objetdata = {}
            response?.data?.map((item)=>{
                objetdata[item.info] = item.contenu
            })
           
          
            return objetdata
        } catch (error) {
            throw new Error('Failed to fetch conditions')
        }
    }
)



export const editCondition= createAsyncThunk(
    'conditions/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
      
        try {
            const response = await BaseService.post('/XML/info.send/cond', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)



export const selectData = (state) => state.conditions.data
export const selectIsLoading = (state) => state.conditions.isLoading
export const selectError = (state) => state.conditions.error
export const selectIsDeleted = (state) => state.conditions.isDeleted
export const selectCurrentPage = (state) => state.conditions.currentPage
export default conditionsSlice.reducer
