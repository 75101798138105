import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const demandesMaintenancesSlice = createSlice({
    name: 'demandesMaintenances',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsDemandesMaintenances: (state) => {
            if (state.detailsDemandesMaintenances == null) {
                state.detailsDemandesMaintenances = ''
            }else{
                state.detailsDemandesMaintenances = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsDemandesMaintenances.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsDemandesMaintenances.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsDemandesMaintenances = action.payload
            })
            .addCase(fetchDetailsDemandesMaintenances.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchDemandesMaintenances.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDemandesMaintenances.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchDemandesMaintenances.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createDemandesMaintenance.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createDemandesMaintenance.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createDemandesMaintenance.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteDemandesMaintenance.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteDemandesMaintenance.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteDemandesMaintenance.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchDemandesMaintenances = createAsyncThunk(
    'demandesMaintenances/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/main.dm.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch demandesMaintenances')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsDemandesMaintenances = createAsyncThunk(
    'DemandesMaintenances/fetchDetails',
    async (id) => {
        const data = { 'id': id.id }
        try {
            const DemandesMaintenance = await BaseService.post(
                `/XML/main.dm.load/get_one_dm`, data
            )


            return DemandesMaintenance.data
        } catch (error) {
            throw new Error('Failed to fetch DemandesMaintenances')
        }
    }
)
export const createDemandesMaintenance = createAsyncThunk(
    'demandesMaintenances/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/main.dm.send/ajouter', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editDemandesMaintenance = createAsyncThunk('demandesMaintenances/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/main.dm.send/modifier', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(creation?.error)
    }
})

export const deleteDemandesMaintenance = createAsyncThunk(
    'demandesMaintenance/delete',
   
    async (payload) => {
        const { data, setDemandesMaintenanceAdded } = payload
        try {
            const response = await BaseService.post('/XML/main.dm.send/delete', data)
            if (response.status === 200) {
                
              
                popNotification(creation?.success)
                setDemandesMaintenanceAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
          
            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.demandesMaintenances.data
export const selectIsLoading = (state) => state.demandesMaintenances.isLoading
export const selectError = (state) => state.demandesMaintenances.error
export const selectIsDeleted = (state) => state.demandesMaintenances.isDeleted
export const selectCurrentPage = (state) => state.demandesMaintenances.currentPage
export const resetDetailsDemandesMaintenances = demandesMaintenancesSlice.actions.resetDetailsDemandesMaintenances
export default demandesMaintenancesSlice.reducer
